<template>
  <v-form>
    <v-progress-linear
      indeterminate
      color="orange"
      v-show="connecting && !connected"
    />
    <v-progress-linear
      color="green"
      value="100"
      v-show="!connecting && connected"
    />
    <v-progress-linear
      color="red"
      value="100"
      v-show="!connecting && !connected"
    />
    <v-divider color="#333333"></v-divider>
    <v-container fluid>
      <v-row>
        <v-col cols="1">
          <v-select
            label="Schema"
            v-model="ws_schema"
            :items="ws_schema_options"
            dense
            :disabled="disallowInput"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Uri"
            dense
            v-on:keydown.enter.prevent
            :disabled="disallowInput"
            v-model="ws_uri"
          />
        </v-col>
        <v-col cols="1">
          <v-text-field
            label="Port"
            dense
            v-on:keydown.enter.prevent
            :disabled="disallowInput"
            v-model="ws_port"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Path"
            dense
            v-on:keydown.enter.prevent
            :disabled="disallowInput"
            v-model="ws_path"
          />
        </v-col>
        <v-col cols="1">
          <v-text-field
            label="Protocol"
            dense
            persistent-placeholder
            v-on:keydown.enter.prevent
            :disabled="disallowInput"
            v-model="ws_protocol"
          />
        </v-col>
        <v-col cols="1">
          <v-btn
            small
            width="100%"
            class="green--text text--darken-3"
            :disabled="disallowInput"
            v-show="!connected"
            @click="connect()"
          >
            <v-icon small>mdi-flash</v-icon>
          </v-btn>
          <v-btn
            small
            width="100%"
            class="red--text text--darken-3"
            :disabled="!disallowInput"
            v-show="connected"
            @click="disconnect()"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            small
            width="100%"
            :class="`${debug_event_in_console ? 'red' : 'green'} white--text`"
            @click="switchEventDebug()"
            title="log to console every event"
          >
            <v-icon x-small>mdi-bug</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            small
            width="100%"
            class="orange white--text"
            @click="openCheckPage()"
            title="log to console every event"
          >
            CERT
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import WebSocketConnection from "@/services/WebSocketConnection";
import EventBus from "@/services/EventBus";

export default {
  name: "Connect",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      ws_uri: "127.0.0.1",
      ws_port: "443",
      ws_schema: "wss",
      ws_schema_options: ["ws", "wss"],
      ws_protocol: "",
      ws_path: "/",
      connecting: false,
      connected: false,
      input_disabled: false,
      message2send: "",
      debug_event_in_console: EventBus.debug_enabled,
    };
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created() {
    EventBus.$on("ws-connecting", (active) => (this.connecting = active));
    EventBus.$on("ws-connected", (active) => (this.connected = active));
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    connect() {
      WebSocketConnection.connect(
        `${this.ws_schema}://${this.ws_uri}:${this.ws_port}${this.ws_path}`,
        this.ws_protocol
      );
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    disconnect() {
      WebSocketConnection.disconnect();
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    switchEventDebug() {
      this.debug_event_in_console = !this.debug_event_in_console;
      EventBus.debug_enabled = this.debug_event_in_console;
    },
    openCheckPage() {
      window.open("https://" + this.ws_uri + "/check", "_blank");
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    disallowInput: function () {
      return this.connected || this.connecting;
    },
  },
};
</script>

<style scoped></style>
