<template>
  <v-form v-model="isValidForm" v-show="connected">
    <v-container>
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-on:keydown.enter.prevent
            dense
            single-line
            v-model="message2send"
            :rules="validate_rule"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn small @click="sendMessage" :disabled="!isValidForm"
            >SEND</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <div v-for="preset in message_presets" :key="preset.id">
          <v-btn x-small @click="(e) => (message2send = e.target.innerText)">
            {{ preset.msg }}
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import EventBus from "@/services/EventBus";
import MessageTranscoding from "@/services/MessageTranscoding";
import WebSocketConnection from "@/services/WebSocketConnection";

export default {
  name: "MessageSend",
  created() {
    EventBus.$on("ws-connected", (active) => (this.connected = active));
  },
  data() {
    return {
      connected: false,
      isValidForm: false,
      message2send: "",
      validate_rule: [
        (v) => v.replace(/\s/g, "") !== "",
        (v) => /^(?:([a-fA-F0-9]{2})\s*)+$/.test(v),
      ],
      message_presets: [
        { id: 1, msg: "02 00 00 00 00 03" },
        { id: 2, msg: "02 FF 00 00 FF 03" },
        { id: 3, msg: "02 20 00 00 20 03" },
        { id: 4, msg: "02 64 00 07 68 09 00 00 01 0E DC C7 03" },
        { id: 5, msg: "02 C4 00 00 C4 03" },
        { id: 6, msg: "02 8F 00 00 8F 03" },
        {
          id: 7,
          msg: "02 B0 00 25 01 01 02 00 04 00 01 02 06 00 08 00 02 03 0A 00 0C 00 03 01 0E 00 10 00 04 02 12 00 14 00 01 03 16 00 17 00 02 8A 03",
        },
        { id: 8, msg: "02 BA 00 01 01 BC 03" },
        { id: 9, msg: "02 C5 00 03 01 0C 1E F3 03" },
      ],
    };
  },
  methods: {
    sendMessage: function () {
      const chunks = function* (arr, n) {
        for (let i = 0; i < arr.length; i += n) {
          yield arr.slice(i, i + n);
        }
      };

      let messageHexs = [
        ...chunks(this.message2send.replace(/\s/g, "").split(""), 2),
      ].map(function (value) {
        value = value.join("");
        return MessageTranscoding.transHex2Charcode(value);
      });

      let buffer = new Uint8Array(messageHexs.length);
      for (let i = 0; i < messageHexs.length; i++) {
        buffer[i] = messageHexs[i];
      }

      WebSocketConnection.send(buffer);
    },
  },
};
</script>

<style scoped></style>
