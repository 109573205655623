<template>
  <v-col :cols="cols" v-show="connected">
    <v-btn x-small @click="clearLog" class="grey white--text">
      <v-icon small>mdi-delete</v-icon>
      clear
    </v-btn>
    |
    <v-btn
      x-small
      v-show="logging_enabled"
      @click="logging_enabled = false"
      class="red darken-3 white--text"
      ><v-icon small>mdi-stop</v-icon>log</v-btn
    >
    <v-btn
      x-small
      v-show="!logging_enabled"
      @click="logging_enabled = true"
      class="green darken-3 white--text"
      ><v-icon small>mdi-play</v-icon>log</v-btn
    >
    |
    <v-btn
      x-small
      v-show="!scroll_to_bottom"
      @click="scroll_to_bottom = true"
      class="red darken-3 white--text"
      ><v-icon small>mdi-stop</v-icon>scroll</v-btn
    >
    <v-btn
      x-small
      v-show="scroll_to_bottom"
      @click="scroll_to_bottom = false"
      class="green darken-3 white--text"
      ><v-icon small>mdi-arrow-down</v-icon>scroll</v-btn
    >
    |
    <v-btn-toggle @change="decodeChanged" v-model="switch_decode_log" mandatory>
      <v-btn x-small>HEX</v-btn>
      <v-btn x-small>DEC</v-btn>
      <v-btn x-small>ASCII</v-btn>
    </v-btn-toggle>
    <v-textarea
      ref="textarea"
      v-on:keydown.enter.prevent
      :label="label"
      v-model="log_rows"
      rows="10"
    ></v-textarea>
  </v-col>
</template>

<script>
import EventBus from "@/services/EventBus";
import MessageTranscoding from "@/services/MessageTranscoding";

export default {
  name: "MessageLogArea",
  props: {
    label: String,
    cols: String,
    event_log: String,
  },
  data() {
    return {
      logging_enabled: true,
      log_rows: "",
      switch_decode_log: 0,
      scroll_to_bottom: true,
      connected: false,
    };
  },
  created() {
    EventBus.$on(this.event_log, this.onNewData);
    EventBus.$on("ws-connected", (active) => (this.connected = active));
  },
  methods: {
    onNewData(data) {
      for (const i in data) {
        const byte = data[i];

        switch (this.switch_decode_log) {
          case 0:
            this.log_rows += MessageTranscoding.transCharcode2Hex(byte) + " ";
            break;
          case 2:
            this.log_rows += MessageTranscoding.transCharcode2Byte(byte) + " ";
            break;
          case 1:
            this.log_rows += byte + " ";
            break;
        }
      }

      this.log_rows += "\r\n";

      if (this.scroll_to_bottom) {
        this.$refs.textarea.$refs.input.scrollTop =
          this.$refs.textarea.$refs.input.scrollHeight;
      }
    },
    clearLog() {
      this.log_rows = "";
    },
    decodeChanged(e) {
      console.log("switch_decode_log:" + this.switch_decode_log);
      console.log(e);
    },
  },
};
</script>

<style scoped></style>
