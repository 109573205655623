interface Dictionary<T> {
  [Key: string]: T;
}

class EventBus {
  public debug_enabled = true;
  // eslint-disable-next-line @typescript-eslint/ban-types
  private readonly events: Dictionary<Array<Function>> = {};

  // eslint-disable-next-line @typescript-eslint/ban-types
  public $on(eventName: string, fn: Function) {
    this.getEventName(eventName).push(fn);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public $off(eventName: string, fn: Function) {
    if (!this.exists(eventName)) {
      return;
    }

    for (let i = 0; i < this.events[eventName].length; i++) {
      if (this.events[eventName][i] === fn) {
        this.events[eventName].splice(i, 1);
        return;
      }
    }
  }

  public $emit(eventName: string, data: unknown) {
    if (this.debug_enabled) {
      console.log([eventName, data]);
    }

    if (!this.exists(eventName)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    this.events[eventName].forEach((fn: Function) => fn(data));
  }

  private exists(eventName: string): boolean {
    return this.events[eventName] !== undefined;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private getEventName(eventName: string): Array<Function> {
    if (!this.exists(eventName)) {
      this.events[eventName] = [];
    }

    return this.events[eventName];
  }
}

export default new EventBus();
