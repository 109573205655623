





























import Vue from "vue";
import MessageLogArea from "@/components/MessageLogArea.vue";
import Connect from "@/components/Connect.vue";

import "@mdi/font/css/materialdesignicons.css";
import MessageSend from "@/components/MessageSend.vue";

export default Vue.extend({
  name: "App",
  components: {
    MessageSend,
    Connect,
    MessageLogArea,
  },
  data() {
    return {
      title: "",
      version: "",
    };
  },
  mounted() {
    this.title =
      document.querySelector('meta[name="title"]')?.getAttribute("content") ??
      "";
    this.version =
      document.querySelector('meta[name="version"]')?.getAttribute("content") ??
      "";
  },
});
