import EventBus from "@/services/EventBus";
import MessageTranscoding from "@/services/MessageTranscoding";

class WebSocketConnection {
  // add union type because _webSocket is not defined in constructor or in declaration
  private _webSocket: unknown | WebSocket | null;

  get webSocket(): WebSocket | null {
    if (this._webSocket instanceof WebSocket) {
      return this._webSocket;
    }
    return null;
  }

  set webSocket(value: WebSocket | null) {
    this._webSocket = value;
  }

  constructor() {
    EventBus.$on("ws-connected", () => EventBus.$emit("ws-connecting", false));
    EventBus.$on("ws-close", () => EventBus.$emit("ws-connecting", false));
    EventBus.$on("ws-error", () => EventBus.$emit("ws-connecting", false));

    window.addEventListener("beforeunload", () => this.disconnect());
  }

  public connect(url: string, protocol: string): void {
    this.disconnect();

    EventBus.$emit("ws-connecting", true);

    this.webSocket = new WebSocket(url, protocol.trim() === "" ? [] : protocol);
    this.webSocket.binaryType = "arraybuffer";
    this.webSocket.onopen = this.onConnect;
    this.webSocket.onmessage = this.onData;
    this.webSocket.onclose = this.onDisconnect;
    this.webSocket.onerror = this.onError;
  }

  public disconnect() {
    if (this.webSocket !== null) {
      this.webSocket.close();
    }
  }

  public onConnect(e: Event) {
    EventBus.$emit("ws-connected", true);
  }

  public send(data: Uint8Array) {
    if (this.webSocket === null) {
      throw Error("No websocket connection");
    }
    this.webSocket.send(data);
    EventBus.$emit("ws-data-out", data);
  }

  public onData(e: MessageEvent) {
    const byteArray: Uint8Array = new Uint8Array(e.data);

    const data = [];
    for (let i = 0; i < byteArray.length; i++) {
      data[i] = byteArray[i].toString();
    }
    EventBus.$emit("ws-data-in", byteArray);
  }

  public onError(e: Event) {
    EventBus.$emit("ws-error", e);
  }

  public onDisconnect(e: Event) {
    EventBus.$emit("ws-close", false);
    EventBus.$emit("ws-connected", false);
  }
}

export default new WebSocketConnection();
