interface Dictionary<T> {
  [Key: string]: T;
}

class MessageTranscoding {
  private symbols: Array<string> = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
  ];

  private table_charcode_hex: Dictionary<string> = {};
  private table_charcode_byte: Dictionary<string> = {};

  private table_hex_charcode: Dictionary<string> = {};
  private table_hex_byte: Dictionary<string> = {};

  private table_byte_hex: Dictionary<string> = {};
  private table_byte_charcode: Dictionary<string> = {};

  constructor() {
    let i = 0;
    for (const s1 of this.symbols) {
      for (const s2 of this.symbols) {
        const hex = s1 + "" + s2;
        const charcode = "" + i;

        const unsigned = new Uint8Array([i]);

        const byte = String.fromCharCode(unsigned[0]);

        this.table_charcode_hex[charcode] = hex;
        this.table_charcode_byte[charcode] = byte;

        this.table_hex_charcode[hex] = charcode;
        this.table_hex_byte[hex] = byte;

        this.table_byte_hex[byte] = hex;
        this.table_byte_charcode[byte] = charcode;
        i++;
      }
    }

    //console.log(this.table_charcode_hex)
    //console.log(this.table_charcode_byte)
    //console.log(this.table_hex_charcode)
    //console.log(this.table_hex_byte)
    //console.log(this.table_byte_hex)
    //console.log(this.table_byte_charcode)
  }

  transCharcode2Hex(charcode: string): string {
    return this.table_charcode_hex[charcode];
  }

  transCharcode2Byte(charcode: string): string {
    return this.table_charcode_byte[charcode];
  }

  transHex2Charcode(hex: string): string {
    return this.table_hex_charcode[hex.toLowerCase()];
  }

  transHex2Byte(hex: string): string {
    return this.table_hex_byte[hex.toLowerCase()];
  }

  transByte2Hex(byte: string): string {
    return this.table_byte_hex[byte];
  }

  transByte2Charcode(byte: string): string {
    return this.table_byte_charcode[byte];
  }
}

export default new MessageTranscoding();
